<div class="container">
    <app-header></app-header>
</div>

<main role="main" class="container">
    <div class="row">
        <div class="col-md-8 blog-main">

            <div class="blog-post">
                <h3 class="blog-post-title">Besuch in der Synagoge</h3>
                <p>Für alle Interessenten, z.B. Schulklassen, besteht die Möglichkeit, unsere Synagoge zu besichtigen
                    und authentisches Judentum in unserer Gemeinde kennenlernen. Sie können bei uns etwas Neues lernen,
                    ohne Scheu Fragen stellen, diskutieren und neue Leute kennenlernen</p>
            </div><!-- /.blog-post -->

        </div><!-- /.blog-main -->

        <aside class="col-md-4 blog-sidebar">
            <app-info-box></app-info-box>
        </aside><!-- /.blog-sidebar -->
    </div><!-- /.row -->

</main><!-- /.container -->

<app-related-articles></app-related-articles>
