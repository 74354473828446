<div class="container">
    <app-header></app-header>
</div>

<main role="main" class="container">
    <div class="row">
        <div class="col-md-8 blog-main">

            <div class="blog-post">
                <h3 class="blog-post-title">Synagoge</h3>
                <p>Die Synagoge ist das jüdische Gebetshaus, in dem gebetet und gelernt wird. Die Synagoge bildete seit
                    jeher den Mittelpunkt des jüdischen Lebens in der Diaspora, um den sich alle anderen Einrichtungen
                    gruppierten. In den vielen Jahrhunderten jüdischer Geschichte in Nürnberg gab es etliche Synagogen,
                    von denen kaum Spuren oder lediglich Gedenksteine geblieben sind.</p>
                <p>Mitte des 19. Jahrhunderts jedoch schien ein neues Zeitalter zu beginnen, als Restriktionen
                    aufgehoben und Juden den anderen Bürgern rechtlich weitgehend gleichgestellt wurden. In der Folge
                    entstanden zahlreiche Synagogen und Gebetsstuben in Nürnberg, darunter die bekannteste in der
                    Essenweinstraße. Sie alle wurden in der Pogromnacht 1938 von den Nazis zerstört und geplündert.</p>
                <p>Durch Juden aus Osteuropa, die nach dem 2. Weltkrieg als sogenannte Displaced Persons in Nürnberg und
                    Region auf die Möglichkeit warteten, nach Israel einreisen zu dürfen, gab es ein kurzes Aufflammen
                    jüdisch-religiösen Lebens in Nürnberg. Nach ihrer Umsiedlung (Alija) in den Staat Israel dauerte es
                    dann allerdings über fünf Jahrzehnte, bis sich erneut ein religiöser (orthodoxer) Gebetskreis
                    bildete. Zunächst fanden die Gebete in der Wohnung unseres Rabbiners statt, danach gab es für einige
                    Jahre eine Gebetsstube am Fenitzer Platz. Nachdem unsere Gemeinschaft G-tt sei Dank immer größer
                    wurde, musste dann ein neues Zuhause gesucht werden.</p>
                <p>Unsere heutige Synagoge und Gemeindehaus wurden stufenweise ab dem Jahr 2010 in Betrieb genommen, der
                    Erwerb wurde durch die Spenden der Familie Chitrik (New York und Israel) und Rohr (New York / Miami)
                    finanziert, die Sanierung dauert noch an.</p>
                <p>An dieser Stelle sei unseren Förderern gedankt, mit deren Hilfe die Sanierung erfolgt. Mögen Sie
                    G-ttes Segen für ihre Hilfe erhalten!</p>
                <p>Diese Institutionen und Personen sind:</p>
                <ul>
                    <li>Freistaat Bayern</li>
                    <li>Stiftung Stuttgarter Lehrhaus</li>
                    <li>Stiftung für Freundschaft und Sicherheit jüdischer Organisationen (Israel)</li>
                    <li>Fund of Emergency Assistance to Communities der Jewish Agency for Israel</li>
                    <li>Stiftung Keren Mikwaot (New York)</li>
                    <li>RCE Rabbinical Center of Europe</li>
                    <li>Mitglieder und Freunde unsere Gemeinde, die mit ihrem hart erarbeiteten Geld die Synagoge
                        unterstützen
                    </li>
                </ul>
            </div><!-- /.blog-post -->

        </div><!-- /.blog-main -->

        <aside class="col-md-4 blog-sidebar">
            <app-info-box></app-info-box>
        </aside><!-- /.blog-sidebar -->
    </div><!-- /.row -->

</main><!-- /.container -->

<app-related-articles></app-related-articles>
