<!--div class="p-4 mb-3 bg-light rounded">
    <h4 class="font-italic">Koscherer Laden</h4>
    <p class="mb-0">Unsere Gemeinde verfügt über Koschere Produkte, leckeren Kiddusch Wein sowie Fleisch und Süßes. Schauen Sie nachdem nächsten Schiur gerne vorbei</p>
</div-->

<div class="p-4 mb-3 bg-light rounded">
    <h4 class="font-italic">Jüdische.Info</h4>
    <p class="mb-0">Hier finden Sie weitere Information rund ums Judentum, Gesetze und Bräuche. Sollten Sie weitere
        Fragen haben, stehen wir Ihnen gerne zur Verfügung. Mehr Informationen finden Sie <a
                href="https://de.chabad.org/#utm_source=domain&utm_medium=domain&utm_campaign=Juedische.info"
                target="_blank">hier.</a></p>
</div>

<div class="p-4 mb-3 bg-light rounded">
    <h4 class="font-italic">Jüdischer Kalender</h4>
    <p class="mb-0">Haben Sie schon einen jüdischen Kalender? Wenn nicht, können Sie den aktuellen Kalender mit
        jüdischen Feiertagen und Schabbatzeiten bei uns erhalten! Jüdische Zeiten Online gibt es <a
                href="https://www.myzmanim.com/day.aspx?askdefault=1&vars=52713195&q=n%C3%BCrnberg"
                target="_blank">hier</a>.</p>
</div>
<div class="p-4 mb-3 bg-light rounded">
    <h4 class="font-italic">Nachrichten aus Israel</h4>
    <p class="mb-0"><a href="https://www.israelnationalnews.com/" target="_blank">Hier</a> finden Sie Nachrichten aus
        Israel von <i>IsraelNationalNews</i>.</p>
</div>
