<div class="container">
    <app-header></app-header>
</div>

<main role="main" class="container">
    <div class="row">
        <div class="col-md-8 blog-main">

            <div class="blog-post">
                <h3 class="blog-post-title">Kinderunterricht</h3>

                <p>Jüdische Ausbildung für Kinder, das Studium der Tora und die Unterweisung in Mizwot sind
                    unentbehrlich und lebensnotwendig im Judentum. Unsere Rabbiner haben das erkannt und schon vor weit
                    über zwei Tausend Jahren die allgemeingültige Unterrichtspflicht für Kinder verordnet. Lange vor
                    Entstehung der Schulen in der heutigen Gestalt und Einführung der allgemeinen Schulpflicht in Europa
                    wurde diese Verpflichtung von Eltern in allen Gemeinschaften streng befolgt.</p>
                <p>Im Rahmen der Sonntagsschule oder im Privatunterricht lernen Kinder bei uns die Tora und jüdische
                    Traditionen kennen. Eine besondere Stellung nimmt die Vorbereitung auf die religiöse Volljährigkeit
                    (Bar-Mizwa für Jungen mit 13 Jahren und Bat-Mizwa für Mädchen mit 12 Jahren) ein. Nach dem
                    Tora-Gesetz gelten Jugendliche ab diesem Alter im Wesentlichen als erwachsen, müssen die Mizwot in
                    vollem Umfang befolgen und mit grundlegenden Inhalten des Judentums vertraut sein.</p>
            </div><!-- /.blog-post -->

        </div><!-- /.blog-main -->

        <aside class="col-md-4 blog-sidebar">
            <app-info-box></app-info-box>
        </aside><!-- /.blog-sidebar -->
    </div><!-- /.row -->

</main><!-- /.container -->

<app-related-articles></app-related-articles>
