<header class="blog-header py-3">
    <div class="row flex-nowrap justify-content-between align-items-center" style="height:100px">
        <div class="col-4 pt-1">
            <a class="text-muted" href="/">
                <img src="assets/img/logo.png" height="180px">
            </a>
        </div>
        <div class="col-4 text-center d-none d-sm-none d-md-block">
            <a class="blog-header-logo text-dark" href="/">Kehal Adat Jeschurun</a>
        </div>
        <div class="col-4 d-flex justify-content-end align-items-center">
            <span>בס"ד</span>
        </div>
    </div>
</header>
<div id="carouselExampleCaptions" class="carousel slide" data-ride="carousel">
    <ol class="carousel-indicators">
        <li data-target="#carouselExampleCaptions" data-slide-to="0" class="active"></li>
        <li data-target="#carouselExampleCaptions" data-slide-to="1"></li>
        <li data-target="#carouselExampleCaptions" data-slide-to="2"></li>
        <li data-target="#carouselExampleCaptions" data-slide-to="3"></li>
        <li data-target="#carouselExampleCaptions" data-slide-to="4"></li>
        <li data-target="#carouselExampleCaptions" data-slide-to="5"></li>
        <li data-target="#carouselExampleCaptions" data-slide-to="6"></li>
    </ol>
    <div class="carousel-inner">
        <div class="carousel-item active">
            <div class="jumbotron-gemeinde p-4 p-md-5 text-white rounded bg-dark">
                <!--div class="col-md-6 px-0">
                    <h1 class="display-5 font-italic">Jüdisches</h1>
                    <h2 class="display-5 font-italic">Leben</h2>
                    <h2 class="display-5 font-italic">in Nürnberg</h2>
                </div-->
            </div>
        </div>
        <div class="carousel-item">
            <div class="jumbotron-sefer-tora p-4 p-md-5 text-white rounded bg-dark">
                <!--div class="col-md-6 px-0">
                    <h1 class="display-5 font-italic">Unser</h1>
                    <h2 class="display-5 font-italic">Fundament</h2>
                </div-->
            </div>
        </div>
        <div class="carousel-item">
            <div class="jumbotron-gebet p-4 p-md-5 text-white rounded bg-dark">
                <!--div class="col-md-6 px-0">
                    <h1 class="display-5 font-italic">Tägliche</h1>
                    <h2 class="display-5 font-italic">G-ttesdienste</h2>
                </div-->
            </div>
        </div>
        <div class="carousel-item">
            <div class="jumbotron-pessach p-4 p-md-5 text-white rounded bg-dark">
                <!--div class="col-md-6 px-0">
                    <h1 class="display-5 font-italic">Galla</h1>
                    <h2 class="display-5 font-italic">Dinner</h2>
                </div-->
            </div>
        </div>
        <div class="carousel-item">
            <div class="jumbotron-chanukka-oper p-4 p-md-5 text-white rounded bg-dark">
                <!--div class="col-md-6 px-0">
                    <h1 class="display-5 font-italic">Galla</h1>
                    <h2 class="display-5 font-italic">Dinner</h2>
                </div-->
            </div>
        </div>
        <div class="carousel-item">
            <div class="jumbotron-chanukkia-kehrt-zurueck p-4 p-md-5 rounded bg-dark">
                <!--div class="col-md-6 px-0">
                    <h1 class="display-5 font-italic">Galla</h1>
                    <h2 class="display-5 font-italic">Dinner</h2>
                </div-->
            </div>
        </div>
        <div class="carousel-item">
            <div class="jumbotron-chanukka-outside p-4 p-md-5 rounded bg-dark">
                <!--div class="col-md-6 px-0">
                    <h1 class="display-5 font-italic">Galla</h1>
                    <h2 class="display-5 font-italic">Dinner</h2>
                </div-->
            </div>
        </div>
    </div>
    <a class="carousel-control-prev" href="#carouselExampleCaptions" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carouselExampleCaptions" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
    </a>
</div>


<!-- non-mobile start -->
<div class="d-none d-sm-none d-md-block">
    <ul ngbNav #nav="ngbNav" class="nav-tabs justify-content-center">
        <li ngbNavItem><a class="nav-link text-muted" href="/">Gemeinde</a>
        </li>
        <li ngbDropdown class="nav-item">
            <a class="nav-link text-muted" id="dropdownBasic1" ngbDropdownToggle>Einrichtungen</a>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                <a ngbDropdownItem href="/synagoge">Synagoge
                </a>
                <a ngbDropdownItem href="/rabbinat">Rabbinat
                </a>
                <a ngbDropdownItem href="/mikwe">Mikwe
                </a>
                <a ngbDropdownItem href="/kinder-unterricht">Kinderunterricht
                </a>
                <a ngbDropdownItem href="/kinder-betreuung">Kinderbetreuung
                </a>
                <a ngbDropdownItem href="/koscheres-essen">koscheres Essen
                </a>
                <a ngbDropdownItem href="/judentum-zu-hause">Judentum zu Hause
                </a>
                <a ngbDropdownItem href="/tora-lernen">Tora-Lernen
                </a>
                <a ngbDropdownItem href="/jugendclub">Jugendclub
                </a>
                <a ngbDropdownItem href="/kalender">Kalender
                </a>
            </div>
        </li>
        <li ngbNavItem class="nav-item">
            <a class="nav-link text-muted" href="/mediathek">Mediathek</a>
        </li>
        <li ngbNavItem class="nav-item">
            <a class="nav-link text-muted" href="/publikationen">Publikationen</a>
        </li>
        <li ngbNavItem class="ml-auto">
            <a class="nav-link text-muted" href="/spenden">Spenden</a>
        </li>
        <li ngbDropdown class="nav-item">
            <a class="nav-link text-muted" id="dropdownBasic2" ngbDropdownToggle>Kontakt</a>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic2">
                <a ngbDropdownItem href="/kontakt">Kontakt
                </a>
                <a ngbDropdownItem href="/besuch-in-der-synagoge">Besuch in der Synagoge
                </a>
            </div>
        </li>
        <li ngbNavItem class="ml">
            <a rel="noopener noreferrer" class="nav-link text-muted" href="http://www.chabad-nuernberg.de"
               target="_blank">Chabad Mittelfranken</a>
        </li>
    </ul>
</div>  <!-- non-mobile end -->

<div class="d-block d-md-none"> <!-- mobile only start -->

    <div ngbDropdown placement="bottom-right" display="dynamic" class="btn-block btn-group">
        <button type="button" role="button" class="btn btn-outline-secondary" id="dropdownMobile" ngbDropdownToggle>
            Menu
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownMobile" class="dropdown text-left">
            <a ngbDropdownItem class="nav-link text-muted" href="/">Gemeinde</a>
            <a ngbDropdownItem class="nav-link text-muted" href="">Einrichtungen</a>
            <a ngbDropdownItem href="/synagoge">Synagoge</a>
            <a ngbDropdownItem href="/rabbinat">Rabbinat</a>
            <a ngbDropdownItem href="/mikwe">Mikwe</a>
            <a ngbDropdownItem href="/kinder-unterricht">Kinderunterricht</a>
            <a ngbDropdownItem href="/kinder-betreuung">Kinderbetreuung</a>
            <a ngbDropdownItem href="/koscheres-essen">koscheres Essen</a>
            <a ngbDropdownItem href="/judentum-zu-hause">Judentum zu Hause</a>
            <a ngbDropdownItem href="/tora-lernen">Tora-Lernen</a>
            <a ngbDropdownItem href="/jugendclub">Jugendclub</a>
            <a ngbDropdownItem href="/kalender">Kalender</a>
            <a ngbDropdownItem href="/mediathek">Mediathek</a>
            <a ngbDropdownItem href="/publikationen">Publikationen</a>
            <a ngbDropdownItem class="nav-link text-muted" href="/spenden">Spenden</a>
            <a ngbDropdownItem class="nav-link text-muted" href="/kontakt">Kontakt</a>
            <a ngbDropdownItem href="/kontakt">Kontakt-Formular
            </a>
            <a ngbDropdownItem href="/besuch-in-der-synagoge">Besuch in der Synagoge
            </a>
            <a ngbDropdownItem rel="noopener noreferrer" class="nav-link text-muted"
               href="http://www.chabad-nuernberg.de"
               target="_blank">Chabad Mittelfranken</a>
        </div>
    </div>

</div> <!-- mobile only start -->
