<div class="container">
    <app-header></app-header>
</div>

<main role="main" class="container">
    <div class="row">
        <div class="col-md-8 blog-main">

            <div class="blog-post">
                <h3 class="blog-post-title">Judentum zu Hause</h3>
                <p>Vierundzwanzig Stunden hat der Tag und vierundzwanzig Stunden am Tag muss ein Jude Gott treu
                    bleiben und nach der Tora leben. Judentum ist keineswegs auf die Synagoge begrenzt, vielmehr
                    erfüllt es jede Sekunde unseres Lebens, daher liegt einer der Schwerpunkte unserer Arbeit darin,
                    Juden in ihrem täglichen jüdischen Leben zu unterstützen, bei dem Erfüllen der Mizwot zu helfen
                    und zu beraten. Wir helfen beim Erwerb von „Judaika“-Gegenständen, die man im Alltag braucht,
                    wie z.B. Kopfbedeckungen für Männer und Frauen, Tallitot (Gebetsschals), Zizijot, Mesusot oder
                    Tefillin (Gebetsriemen). Gefragt sind jüdische Bücher, z.B. Gebets-, Psalmen- und Tora-Bücher. Für
                    Schabat bekommt man bei uns Becher für Kiddusch, Chalot, Chalot-Decken und Kerzen für
                    Hawdala. Für Chanukka (Lichterfest) gibt es Chanukkalichter und -kerzen und für Sukkot
                    (Laubhüttenfest) Etrog und Lulaw. Vor Pessach sind wir der zentrale Ort, wo man Mazzot
                    (ungesäuertes Brot) und andere für Pessach koschere Lebensmittel erhält. Unsere Gemeinde verteilt
                    Informationsmaterial zu Feiertagen und Schabatot und Lernmaterial für Groß und Klein.</p>
                <p>Unser Rabbiner und Ehrenamtliche helfen jedem, seinen religiösen Standard zu erhöhen. Will
                    jemand z.B. seine Ernährung auf koscheres Essen umstellen, bekommt er hierfür praktische
                    Unterweisung und Hilfe.</p>
                <p>Letztendlich geht es aber nicht nur darum, Menschen bloß physisch das Erfüllen der Mizwot zu
                    ermöglichen, sondern ihre Liebe zu G-tt und seiner Tora zu wecken, ihre Verbundenheit mit dem
                    Judentum zu stärken, ihr Leben jüdischer zu machen.</p>
            </div><!-- /.blog-post -->

        </div><!-- /.blog-main -->

        <aside class="col-md-4 blog-sidebar">
            <app-info-box></app-info-box>
        </aside><!-- /.blog-sidebar -->
    </div><!-- /.row -->

</main><!-- /.container -->

<app-related-articles></app-related-articles>
