<!doctype html>
<html lang="de">
<head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
    <meta name="description" content="Jüdische Orthodoxe Gemeinde Nürnberg, alles was man für jüdisches Leben braucht">
    <meta name="author" content="Ron, Freydman, Elyahu Borodin">

    <title>Jüdische Gemeinde - Kehal Adat Jeschurun Nürnberg</title>

    <style>
        .bd-placeholder-img {
            font-size: 1.125rem;
            text-anchor: middle;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }

        @media (min-width: 768px) {
            .bd-placeholder-img-lg {
                font-size: 3.5rem;
            }
        }
    </style>

    <!-- Custom styles for this template -->
</head>

<body>


<router-outlet></router-outlet>


<footer class="blog-footer">
    <div class="container">
        <div class="d-none d-sm-none d-md-block">
            <div class="row justify-content-end">
                <div class="col-4">
                    <p>© Kehal Adat Jeschurun Nürnberg 2020 | 5780</p>
                </div>
                <div class="col-1 offset-3">
                    <a class="p-2 text-muted" href="/">Startseite</a>
                </div>
                <div class="col-1">
                    <a class="p-2 text-muted" href="/kontakt">Kontakt</a>
                </div>

                <div class="col-1">
                    <a class="p-2 text-muted" href="/spenden">Spende</a>
                </div>
                <div class="col-1">
                    <a class="p-2 text-muted" href="/impressum">Impressum</a>
                </div>
                <div class="col-1">
                    <a class="p-2 text-muted" href="/datenschutz">Datenschutz</a>
                </div>
            </div>
        </div>

        <div class="d-block d-md-none"> <!--TODO mobile flipped> not working-->
            <div class="row justify-content-center"><a class="p-2 text-muted" href="/">Startseite</a>
            </div>
            <div class="row justify-content-center"><a class="p-2 text-muted" href="/kontakt">Kontakt</a>
            </div>
            <div class="row justify-content-center"><a class="p-2 text-muted" href="/spenden">Spende</a>
            </div>
            <div class="row justify-content-center"><a class="p-2 text-muted" href="/impressum">Impressum</a></div>
            <div class="row justify-content-center"><a class="p-2 text-muted" href="/datenschutz">Datenschutz</a>
            </div>
            <div class="row justify-content-center">
                <p>© Kehal Adat Jeschurun Nürnberg 2020 | 5780</p>
            </div>
        </div>
    </div>

</footer>
</body>
</html>
