<div class="container">
    <app-header></app-header>
</div>

<main role="main" class="container">
    <div class="row">
        <div class="col-md-8 blog-main">

            <div class="blog-post">
                <h3 class="blog-post-title">Kinderbetreuung</h3>
                <p>Familien mit kleinen Kindern bieten wir eine attraktive Alternative zum Kindergarten an. Unsere
                    Tagesmütter betreuen Kinder in zwei kleinen Gruppen. Es ist der einzige Ort in Nürnberg und
                    Umgebung, wo Ihre Kinder in familiärer Umgebung jüdische Traditionen und Werte erfahren und erlernen
                    können. Der gemeindeeigene Hof mit einer Spielfläche steht den Kindern zur Verfügung, ebenso Essen
                    auf hohem Kaschrut-Standard.</p>
            </div><!-- /.blog-post -->

        </div><!-- /.blog-main -->

        <aside class="col-md-4 blog-sidebar">
            <app-info-box></app-info-box>
        </aside><!-- /.blog-sidebar -->
    </div><!-- /.row -->

</main><!-- /.container -->

<app-related-articles></app-related-articles>
