<div class="container">
    <app-header></app-header>
</div>

<main role="main" class="container">
    <div class="row">
        <div class="col-md-8 blog-main">
            <div class="blog-post">

                <h2 class="blog-post-title">Rabbinat</h2>
                <img src="assets/img/Rabbiner_Eliezer_Chitrik.jpeg" class="rounded float-left"
                     height="360px"
                     style="padding-right: 15px; padding-bottom: 15px"
                     alt="Rabbiner Eliezer Chitrik">
                <p>Unser Rabbiner Elieser Chitrik שליט&#39;&#39;א besitzt eine fundierte Ausbildung und konnte während
                    seiner langjährigen Tätigkeit als Gemeinderabbiner, Mitglied des Deutschen Rabbinerrates und Dajan
                    (Richter an einem jüdischen Religionsgericht) seine Kompetenzen auf vielen Gebieten des jüdischen
                    Religionsrechts (Halacha) erweitern und
                    wertvolle praktische Erfahrungen sammeln, im April 2021 schloss er das Studium zum Dajan (Richter)
                    am jüdischen Religionsgericht (Beth Din) ab.</p>
                <p>Unter seiner Anleitung werden Hochzeiten (Kiduschin) nach jüdischem Religionsrecht
                    (Halacha) durchgeführt und - unter Ägide des Bejt Dins Machsikei Hadat Berlin - auch
                    Übertritte zum Judentum (Gerut) sowie, wenn unvermeidbar, Scheidungen (Gitin).</p>
                <p>Wir organisieren und unterstützen Sie mit ganzer Kraft bei der Organisation von
                    Beschneidung (Brit Mila), Auslösung der Erstgeborenen (Pidjon ha-ben), Bar- und Bat-
                    Mizwa-Vorbereitung und vielem mehr.</p>
                <p>Sie können Rabbiner Eliezer Chitrik unter der E-Mail info[et]kaj-nuernberg.de kontaktieren.</p>
                <p>Sprechstunde nach Vereinbarung unter der Telefonnummer 0174-7705598.</p>
            </div><!-- /.blog-post -->

        </div><!-- /.blog-main -->

        <aside class="col-md-4 blog-sidebar">
            <app-info-box></app-info-box>
        </aside><!-- /.blog-sidebar -->
    </div><!-- /.row -->

</main><!-- /.container -->

<app-related-articles></app-related-articles>
