import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pdf-redirect',
  template: `<p>Redirecting...</p>`,
})
export class RedirectComponent implements OnInit {
  ngOnInit() {
    // External URL
    window.location.href = '/assets/docs/Pessach_Checkliste.pdf';
    // window.location.href = '/assets/docs/netilat-jadaim.pdf';
    // window.location.href = '/assets/docs/sefirat-haomer.pdf';

  }
}