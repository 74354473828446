<div class="container">
    <app-header></app-header>
</div>

<main role="main" class="container">
    <div class="row">
        <div class="col-md-8 blog-main">

            <div class="blog-post">
                <h3 class="blog-post-title">Kalender</h3>
                <p>Der heute in den meisten Ländern übliche gregorianische Kalender ist ein reiner Sonnenkalender mit
                    einer festen Stundenlänge. Im Unterschied zu ihm kombiniert der jüdische Kalender den Mond- und
                    Sonnenzyklus und die Länge einer Stunde ist von der Länge der Tages- und Nachtzeit abhängig.</p>
                <p>Alle jüdischen Feier- und Gedenktage haben ein festes Datum im jüdischen Kalender, sind aber
                    beweglich im Gregorianischen. Viele Gebote im Judentum müssen und können nur in einer bestimmten
                    Tageszeit ausgeführt werden, manche Verbote treten nur mit einer bestimmten Tageszeit in Kraft. Auch
                    Geburtstage und Todestage begehen wird ausschließlich nach jüdischem Kalender. Darüber hinaus ist es
                    wichtig zu wissen, welcher Tora-Wochenabschnitt und Haftara (Abschnitt aus Prophetenbüchern oder
                    heiligen Schriften) am Schabbat gelesen wird. Allein aus diesen Gründen gehört ein jüdischer
                    Kalender in jedes jüdische Haus und hilft zusammen mit entsprechenden Apps, das Leben nach den
                    Gesetzen jüdischer Religion auszurichten.</p>
                <p>Jedes Jahr, kurz vor Rosch Haschana, gibt unsere Gemeinde in Zusammenarbeit mit Chabad Mittelfranken
                    einen jüdischen Kalender heraus. Wenn Sie einen solchen Kalender benötigen, können Sie sich gerne
                    mit uns in Verbindung setzen.</p>
            </div><!-- /.blog-post -->

        </div><!-- /.blog-main -->

        <aside class="col-md-4 blog-sidebar">
            <app-info-box></app-info-box>
        </aside><!-- /.blog-sidebar -->
    </div><!-- /.row -->

</main><!-- /.container -->

<app-related-articles></app-related-articles>
