<div class="container">
    <app-header></app-header>
</div>

<main role="main" class="container">
    <div class="row">
        <div class="col-md-8 blog-main">

            <div class="blog-post">
                <h3 class="blog-post-title">Mediathek</h3>
                <h4>Video</h4>
                <p><a href="https://www.frankenfernsehen.tv/mediathek/video/82-jahre-spaeter-so-geht-juedisches-leben-in-nuernberg/
">82 Jahre später jüdisches Leben in Nürnberg.</a>
                </p>
                <p>
                    <a href="https://www.medienwerkstatt-franken.de/video/leben-nach-gottes-geboten-juedische-orthodoxie-nuernberg/">Jüdische
                        Orthodoxie Nürnberg.</a>
                </p>
                <h4>Artikel</h4>
                <p><a href="https://www.nordbayern.de/2.283/sie-wird-sanft-wie-ein-kind-getragen-1.954347/7.685309">https://www.nordbayern.de/2.283/sie-wird-sanft-wie-ein-kind-getragen-1.954347/7.685309</a>
                </p>
                <p>
                    <a href="https://www.hagalil.com/2016/05/hofpfisterei/">https://www.hagalil.com/2016/05/hofpfisterei/</a>
                </p>
                <p><a href="https://www.hagalil.com/2016/02/chabad/">https://www.hagalil.com/2016/02/chabad/</a></p>
                <p><a href="https://www.strassenkreuzer.info/wp-content/uploads/sk11-11.pdf">https://www.strassenkreuzer.info/wp-content/uploads/sk11-11.pdf</a>
                </p>
            </div><!-- /.blog-post -->

        </div><!-- /.blog-main -->

        <aside class="col-md-4 blog-sidebar">
            <app-info-box></app-info-box>
        </aside><!-- /.blog-sidebar -->
    </div><!-- /.row -->

</main><!-- /.container -->

<app-related-articles></app-related-articles>
