<div class="container">
    <app-header></app-header>
</div>

<main role="main" class="container">
    <div class="row">
        <div class="col-md-8 blog-main">
            <h3 class="pb-4 mb-4 font-italic border-bottom">
                Impressum / Disclaimer
            </h3>

            <div class="blog-post">

                <p>Hiermit erfüllen wir die Impressumspflicht im Internet nach § 5 TMG (Telemediengesetz) (früher § 6 TDG und § 10 MDStV).</p>
                <hr>
                <h4>Jüdische Orthodoxe Religionsgemeinde Nürnberg „Kehal Adat Jeschurun“ e.V</h4>
                <p>Regensburger Str. 54<br/>
                90478 Nürnberg<br/>
                Telefon: +49 (0)911 / 6695285<br/>
                E-Mail: info(at)kaj-nuernberg.de</p>

                <h4>Gemeinschaftlich vertretungsberechtigt:</h4>
                <p>Chaim Eliezer Chitrik und Ester Chitrik<br/>
                c/o Regensburger Str. 54<br/>
                90478 Nürnberg
                </p>

                <p>
                Das Impressum gilt für: http://kaj-nuernberg.de<br/>
                Registergericht: Amtsgericht Nürnberg<br/>
                Registernummer: VR 202189
                </p>

                <h4>Verantwortlich für redaktionelle Inhalte i.S.d. § 55 Abs. 2 RStV:</h4>
                <p>
                Chaim Eliezer Chitrik<br/>
                c/o Regensburger Str. 54<br/>
                90478 Nürnberg
                </p>
                <h4>Haftungshinweis:</h4>
                <p>Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.</p>
                <h4>Urheberrecht</h4>
                <p>Alle innerhalb des Internetangebotes genannten und ggf. durch Dritte geschützten Marken- und Warenzeichen unterliegen uneingeschränkt den Bestimmungen des jeweils gültigen Kennzeichenrechts und den Besitzrechten der jeweiligen eingetragenen Eigentümer. Das Copyright für veröffentlichte, vom Autor selbst erstellte Objekte bleibt allein beim Autor der Seiten. Eine Vervielfältigung oder Verwendung solcher Grafiken, Tondokumente, Videosequenzen und Texte in anderen elektronischen oder gedruckten Publikationen ist ohne ausdrückliche Zustimmung des Autors nicht gestattet. Dies gilt insbesondere für alle Fotos und Tonbeiträge sowie für das Logo, auch in veränderter Form.
                </p>
            </div><!-- /.blog-post -->


        </div><!-- /.blog-main -->

        <aside class="col-md-4 blog-sidebar">
            <app-info-box></app-info-box>
        </aside><!-- /.blog-sidebar -->

    </div><!-- /.row -->

</main><!-- /.container -->
