<div class="container">
    <app-header></app-header>
</div>

<main role="main" class="container">
    <div class="row">
        <div class="col-md-8 blog-main">

            <div class="blog-post">
                <h3 class="blog-post-title">Publikationen</h3>
                <p>Hier finden Sie Material zum Lernen judischer Gesetze und Bräuche:</p>
                <ul>
                    <li>
                        <a
                            href="https://kaj-nuernberg-files.s3.eu-west-1.amazonaws.com/Slichot-Gesetze+und+Bra%CC%88uche.pdf">Slichot:
                            Gesetze und Bräuche</a>
                    </li>
                    <li>
                        <a
                            href="https://kaj-nuernberg-files.s3.eu-west-1.amazonaws.com/Hatarat%20Nedarim%20-%20Aufheben%20von%20Gelu%CC%88bden.pdf">Hatarat
                            Nedarim:
                            Aufheben von Gelübden</a>
                    </li>
                    <li>
                        <a href="https://kaj-nuernberg-files.s3.eu-west-1.amazonaws.com/Pessach+Checkliste+-+KAJ.pdf">Pessach
                            Checkliste
                        </a>
                    </li>
                    <li>
                        <a href="https://kaj-nuernberg-files.s3.eu-west-1.amazonaws.com/netilat-jadaim.pdf">
                            Netilat Jadajim
                        </a>
                    </li>
                    <li>
                        <a href="https://kaj-nuernberg-files.s3.eu-west-1.amazonaws.com/sfirat-haomer.pdf">
                            Sefirat haOmer
                        </a>
                    </li>
                </ul>
            </div><!-- /.blog-post -->

        </div><!-- /.blog-main -->

        <aside class="col-md-4 blog-sidebar">
            <app-info-box></app-info-box>
        </aside><!-- /.blog-sidebar -->
    </div><!-- /.row -->

</main><!-- /.container -->

<app-related-articles></app-related-articles>