import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-kinderunterricht',
  templateUrl: './kinderunterricht.component.html',
  styleUrls: ['./../app.component.css']
})
export class KinderunterrichtComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
