<div class="container">
    <app-header></app-header>
</div>

<main role="main" class="container">
    <div class="row">
        <div class="col-md-8 blog-main">

            <div class="blog-post">
                <h3 class="blog-post-title">Tora-Lernen</h3>
                <p>Völlig ortsunabhängig, von Sinai bis Jerusalem, von Babylon bis Cordoba und von Tunis bis nach Krakau
                    war und ist die wichtigste Beschäftigung des jüdischen Volkes das Studium der Tora. Es ist neben dem
                    Tempel und dem Land Israel einer der Grundpfeiler jüdischer Lebensweise und lebensnotwendig für den
                    Erhalt und die Weitergabe der jüdischen Religion über alle Generationen unserer mehr als
                    dreitausendjährigen Geschichte. Jeder Jude muss entsprechend seiner persönlichen und familiären
                    Situation Zeit finden, sich mit der Tora zu befassen. Dieses Gebot betrifft nicht nur Religiöse und
                    Gebildete, sondern alle Juden. So war und ist es üblich, dass auch Menschen, die einfachste Berufe
                    ausüben, sich ausgiebig mit dem Erlernen schwierigster Inhalte und Gesetze beschäftigen.</p>
                <p>Angebote unserer Gemeinde:</p>
                <ul>
                    <li>Kinder-Sonntagsschule</li>
                    <li>Unterricht für Jugendliche</li>
                    <li>Individuelle Vorbereitung auf Bar- bzw. Bat- Mizwa (jüdische Volljährigkeit).</li>
                    <li>Individueller Unterricht durch unseren Rabbiner für alle Wissensgrade</li>
                    <li>Täglich Abendkolel (Abendschule für Erwachsene)</li>
                    <li>Wöchentlich Kolel für Frauen</li>
                    <li>Weitere Angebote befinden sich im Ausbau</li>
                </ul>
                <p>Interessiert? Kontaktieren Sie uns über die Homepage oder telefonisch.</p>
            </div><!-- /.blog-post -->

        </div><!-- /.blog-main -->

        <aside class="col-md-4 blog-sidebar">
            <app-info-box></app-info-box>
        </aside><!-- /.blog-sidebar -->
    </div><!-- /.row -->

</main><!-- /.container -->

<app-related-articles></app-related-articles>
