<div class="container">
    <div class="row mb-2">
        <div class="col-md-6">
            <div class="row no-gutters border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                <div class="col p-4 d-flex flex-column position-static">
                    <strong class="d-inline-block mb-2 text-primary">Bet Hamikdasch</strong>
                    <h3 class="mb-0">Unser Tempel</h3>
                    <p class="card-text mb-auto">Unser heiliger Tempel.
                        Wie er war, wie er wird.... <br>
                        Infos von </p>
                    <a href="http://templeinstitute.org" target="_blank" class="stretched-link">templeinstitute.org</a>
                </div>
                <div class="col-auto d-none d-lg-block">

                    <img src="assets/img/bet-hamikdash.png" class="rounded p-4"
                         style="height: 200px"
                         alt="Rabbiner Eliezer Chitrik">
                </div>
            </div>
        </div>
        <!--div class="col-md-6">
            <div class="row no-gutters border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                <div class="col p-4 d-flex flex-column position-static">
                    <strong class="d-inline-block mb-2 text-success">Israel</strong>
                    <h3 class="mb-0">Waren Sie schon in Israel</h3>
                    <p class="mb-auto">Was muss ich über das heilige Land wissen?</p>
                    <a href="#" class="stretched-link">Hier erfahren Sie mehr...</a>
                </div>
                <div class="col-auto d-none d-lg-block">
                    <svg class="bd-placeholder-img" width="200" height="250" xmlns="http://www.w3.org/2000/svg"
                         preserveAspectRatio="xMidYMid slice" focusable="false" role="img"
                         aria-label="Placeholder: Thumbnail"><title>Placeholder</title>
                        <rect width="100%" height="100%" fill="#55595c"/>
                        <text x="50%" y="50%" fill="#eceeef" dy=".3em">Thumbnail</text>
                    </svg>
                </div>
            </div>
        </div-->
    </div>
</div>
