import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-kalender',
  templateUrl: './kalender.component.html',
  styleUrls: ['./../app.component.css']
})
export class KalenderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
