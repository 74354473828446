<div class="container">
    <app-header></app-header>
</div>

<main role="main" class="container">
    <div class="row">
        <div class="col-md-8 blog-main">

            <div class="blog-post">
                <h3 class="blog-post-title">Kontakt</h3>
                <!-- form goes here -->
                <form (ngSubmit)="processForm()">
                    <div class="form-group">
                        <input type="email" class="form-control" id="inputEmail" placeholder="E-Mail" name="email"
                               [(ngModel)]="email">
                    </div>
                    <div class="form-group">
                        <input type="email" class="form-control" id="inputName" placeholder="Name" [(ngModel)]="name" name="name">
                    </div>
                    <div class="form-group">
                        <input type="text" class="form-control" id="subject" placeholder="Betreff" [(ngModel)]="subject"  name="subject">
                    </div>
                    <!-- message -->
                    <div class="form-group">
                        <textarea class="form-control" id="forControlTextArea" aria-describedby="desciptionMessage" [(ngModel)]="message"  name="message"
                                  rows="3"></textarea>
                        <small id="desciptionMessage" class="form-text text-muted">Wir behandeln Ihre Daten
                            vertraulich.</small>
                    </div>
                    <div class="row justify-content-end mr-auto">
                        <button type="submit" class="btn btn-primary mb-2">Abschicken</button>
                    </div>
                </form>
            </div><!-- /.blog-post -->

        </div><!-- /.blog-main -->

        <aside class="col-md-4 blog-sidebar">
            <app-info-box></app-info-box>
        </aside><!-- /.blog-sidebar -->
    </div><!-- /.row -->

</main><!-- /.container -->

<app-related-articles></app-related-articles>
