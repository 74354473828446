import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-contact-formular',
  templateUrl: './contact-formular.component.html',
  styleUrls: ['./../app.component.css']
})
export class ContactFormularComponent {
  name: string;
  subject: string;
  email: string;
  message: string;

  constructor(
    private http: HttpClient
  ) {}

  processForm() {
    const form = {
      name: this.name,
      email: this.email,
      subject: this.subject,
      message: this.message
    };
    this.http.post('https://jc0xo0gu5e.execute-api.eu-west-1.amazonaws.com/dev/contact-form', form).subscribe(
      (response) => alert('Wir haben Ihre Nachricht erhalten und kümmern uns um Ihr anliegen.'),
      (error) => alert('Es gab leider einen Fehler: ' + error)
    );
  }

}
