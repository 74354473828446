<div class="container">
    <app-header></app-header>
</div>

<main role="main" class="container">
    <div class="row">
        <div class="col-md-8 blog-main">

            <div class="blog-post">
                <h3 class="blog-post-title">Jugendclub</h3>
                <p>Unser Jugendclub bietet Jugendlichen und jungen Erwachsenen die Möglichkeit, sich in der Jüdischen
                    Gemeinde zu treffen und kennenzulernen. Unsere Ehrenamtlichen organisieren regelmäßig Schabatonim
                    (Schabbat-Treffen). Bei Kiddusch und gedeckter Schabbat-Tafel entwickeln sich schon mal sehr
                    interessante Gespräche über Judentum. Mit Spielen und Vorträgen lernt man über eigene Kultur und
                    Religion und hat Freude dabei.</p>
                <p>An Jugendliche richten sich auch zahlreiche Programme, die von unseren überregionalen Partnern
                    angeboten werden. Das sind z.B. Städtefahrten, Schabbat- und Feiertagsseminare u.v.m.</p>
            </div><!-- /.blog-post -->

        </div><!-- /.blog-main -->

        <aside class="col-md-4 blog-sidebar">
            <app-info-box></app-info-box>
        </aside><!-- /.blog-sidebar -->
    </div><!-- /.row -->

</main><!-- /.container -->

<app-related-articles></app-related-articles>
