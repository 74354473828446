import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-besuch-in-der-synagoge',
  templateUrl: './besuch-in-der-synagoge.component.html',
  styleUrls: ['./../app.component.css']
})
export class BesuchInDerSynagogeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
