<div class="container">
    <app-header></app-header>
</div>

<main role="main" class="container">
    <div class="row">
        <div class="col-md-8 blog-main">
            <div class="blog-post">

                <h2 class="blog-post-title">Spende</h2>
                <p>Die übliche Übersetzung für <b class="font-weight-bold">ZEDAKA</b> ins Deutsche ist "Wohltätigkeit", doch bedeutet Zedaka in
                    Wirklichkeit wesentlich mehr.</p>
                <p>"Wohltätigkeit" (passende Übersetzung wäre „Chessed“) unterstreicht die Tugend des Gebers. Der
                    Empfänger muss nicht unbedingt der Gabe würdig noch der Geber eigentlich zum Geben verpflichtet
                    sein; er tut es aus Güte. Zedaka dagegen leitet sich von dem hebräischen Wort ab, das
                    "Gerechtigkeit" bedeutet; damit wird angezeigt, dass es für einen Menschen recht und billig ist zu
                    geben. Dafür sind zwei Gründe zu nennen: Erstens gibt er ja gar nicht her, was ihm gehört; er gibt
                    nur das, was G-tt ihm anvertraut hat, um davon anderen zu ge-ben. Und zweitens: nachdem jeder auf
                    G-tt für seinen Lebensunterhalt angewiesen ist, obwohl G-tt ganz gewiss niemandem etwas schuldet,
                    so ist auch der Mensch verpflichtet, "Maß für Maß" zurückzuzahlen, obschon er dem Nebenmenschen an
                    sich nichts schuldet.</p>
                <p>Bitte unterstützen Sie unsere Arbeit durch Ihre Spende!</p>

                <div class="row justify-content-md-center">
                    <div class="col-md-auto bg-light p-2">
                        <div class="text-center">
                            <p><strong>Überweisungen</strong></p>
                            <p>Sie können Ihre Spende ganz einfach auf folgendes Konto überweisen:</p>
                            <p><strong>Kontoinhaber:</strong> Jüdische Religionsgemeinde „Adat Jeschurun“.</p>
                            <p><strong>Kreditinstitut:</strong> Sparkasse Nürnberg
                            <p><strong>IBAN:</strong> DE 33 7605 0101 0013 4415 06</p>

                            <p>Vielen Dank für Ihre Spende!<br/>
                                Die Spenden an unsere Gemeinde sind steuerlich absetzbar</p>

                            <p><strong>Sonstige Spenden</strong></p>
                            <p>Sie möchten etwas Besonderes Spenden? Es gibt viele Möglichkeiten!<br/>
                                Sie können Ihre Spende an einen Zweck binden. Außerdem haben Sie die Möglichkeit Ihre
                                Spende
                                bestimmten
                                Menschen oder Anlässen zu widmen.</p>
                            <p>Hier einige Ideen:</p>
                        </div>
                        <ul>
                            <li>Kiddusch anlässlich eines Geburtstags, Jahrzeit, Genesung, Schul- bzw.
                                Studium-abschlusses, u.ä.
                            </li>
                            <li>Gebetsbücher</li>
                            <li>Ferienlager "Camp Gan Israel"</li>
                            <li>Feiertagsprogramm (z.B.: Rosch-Ha-Schana, Pessach, etc.)</li>
                            <li>Gastredner & Vorträge</li>
                        </ul>
                    </div>
                </div>
                <div class="row justify-content-md-center mt-2">
                    <div class="col align-self-center bg-light text-center p-4">
                        Lassen Sie uns drüber reden!
                    </div>
                </div>
            </div><!-- /.blog-post -->
        </div><!-- /.blog-main -->
        <aside class="col-md-4 blog-sidebar">
            <app-info-box></app-info-box>
        </aside><!-- /.blog-sidebar -->
    </div><!-- /.row -->

</main><!-- /.container -->

<app-related-articles></app-related-articles>
