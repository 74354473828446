<div class="container">
    <app-header></app-header>
</div>

<main role="main" class="container">
    <div class="row">
        <div class="col-md-8 blog-main">

            <div class="blog-post">
                <h3 class="blog-post-title">Koscheres Essen</h3>
                <p>Koscher Halten ist eine Mizwa, ein g-ttliches Gebot und eine Verbindung mit G-tt. Auch wenn es viele
                    Erklärungen gibt, warum es gut und richtig ist koscher zu essen, ist der wichtigste Grund für uns,
                    weil G-tt es uns befohlen hat, und durch Erfüllen SEINES Wunsches verbinden wir uns mit IHM.</p>
                <p>Unsere Gemeinde bietet Juden die Versorgung mit koscheren Grundnahrungsmitteln zum Selbstkostenpreis
                    an.</p>
                <p>Viele Lebensmittel, z.B. Obst, Gemüse, Nudeln, viele Fischerzeugnisse können im „normalen“ Handel
                    erworben werden. Selbstverständlich gibt es auch hier Kaschrut-Aspekte, die zu beachten sind.
                    Darüber hinaus können koschere Lebensmittel im Versandhandel bestellt werde. Wir beraten und
                    unterstützen Sie gern.</p>
            </div><!-- /.blog-post -->

        </div><!-- /.blog-main -->

        <aside class="col-md-4 blog-sidebar">
            <app-info-box></app-info-box>
        </aside><!-- /.blog-sidebar -->
    </div><!-- /.row -->

</main><!-- /.container -->

<app-related-articles></app-related-articles>
