<div class="container">
    <app-header></app-header>
</div>

<main role="main" class="container">
    <div class="row">
        <div class="col-md-8 blog-main">

            <div class="blog-post">
                <h3 class="blog-post-title">Frauenmikwe</h3>
                <div class="justify-content-center">
                <img src="assets/img/frauen-mikwe.jpg" class="rounded mh-100 mw-100"
                     style="padding-right: 15px; padding-bottom: 15px"
                     alt="Frauen Mikwe">
                </div>
                <p>Zu den größten Geboten für jüdische Frauen gehören die Gesetze zur Reinheit des
                    Familienlebens (Taharat Mischpacha) und der damit verbundene Besuch der Mikwe.</p>
                <p>Dieses Gebot zu halten, bringt einen besonderen Segen für die Eheleute und ihre Kinder!</p>
                <p>Informationen und Termine für den Mikwebesuch erhalten Sie bei Rabbanit Ester Chitrik
                    (0160-97686770).</p>
                <h3 class="blog-post-title">Männermikwe</h3>
                <img src="assets/img/maenner-mikwe.jpg" class="rounded float-left"
                     height="200px"
                     style="padding-right: 30px; padding-bottom: 15px"
                     alt="Männer Mikwe">
                <p>Das rituelle Bad ist ein weit verbreiteter Brauch unter jüdischen Männern. Viele besuchen die
                    Mikwe am Schabat vor dem Morgengebet (Schacharit), andere, insbesondere Chassiden,
                    vor jedem Morgengebet. Zumindest vor Jom Kippur sollte jeder Mann in die Mikwe tauchen.</p><br><br><br>
                <p>Öffnungszeiten:</p>
                <ul>
                    <li>täglich 40 min. vor Schacharit (Morgengebet) bis 10 min. vor dem Gebet.</li>
                    <li>Freitags von 14:00 Uhr bis 20 min. vor Schabbateingang.</li>
                    <li>Kostenbeitrag 8€/Besuch</li>
                </ul>
                <h3 class="blog-post-title">Geschirrmikwe</h3>
                <p>Neues Geschirr aus Metall, Glas, Keramik oder Porzellan muss vor der ersten Nutzung in die
                    Mikwe getaucht werden.</p>
                <p>Zur Terminvereinbarung oder Beratung kontaktieren Sie bitte Rabbiner Eliezer Chitrik (Tel.
                    0174-7705598).</p>
            </div><!-- /.blog-post -->

        </div><!-- /.blog-main -->

        <aside class="col-md-4 blog-sidebar">
            <app-info-box></app-info-box>
        </aside><!-- /.blog-sidebar -->
    </div><!-- /.row -->

</main><!-- /.container -->

<app-related-articles></app-related-articles>
