<div class="container">
    <app-header></app-header>
</div>

<main role="main" class="container">
    <div class="row">
        <div class="col-md-8 blog-main">

            <div class="blog-post">
                <h2 class="blog-post-title">Wir über uns</h2>
                <p>Unsere religiöse jüdische Gemeinschaft in Nürnberg entstand 2005 mit der
                    Ankunft des der renommierten Chabad-Bewegung angehörenden Rabbiners Eliezer Chitrik
                    und seiner Frau Ester. Aus dieser Gemeinschaft entwickelte sich über die Jahre unsere
                    heutige Jüdische Orthodoxe Religionsgemeinde „Kehal Adat Jeschurun“.</p>
                <p>Wir verstehen uns als orthodoxe, d.h. religiöse
                    jüdische Gemeinde, die sich nach jüdischem Religionsgesetz, der Halacha, ausrichtet und
                    entsprechend organisiert und geleitet wird. Wir
                    sehen uns in der Nachfolge bzw. Neubelebung der bis zur Auflösung durch das Naziregime
                    in Nürnberg beheimateten orthodoxen jüdischen Gemeinden und deren Zusammenschlüsse.</p>
                <p>Unser Selbstverständnis ist es, für alle Juden aus Nürnberg und
                    Umgebung, unabhängig von ihrer religiösen Ausprägung, ein Zuhause zu sein: für
                    Religiöse,
                    Traditionelle, der
                    jüdischen Tradition aufgeschlossene und Nichtreligiöse.</p>
                <p>Auch für Hunderte jüdischer Gäste, die als Touristen, Messebesucher oder
                    Geschäftsreisende nach Nürnberg kommen, sei es aus anderen Teilen Deutschlands oder
                    der ganzen Welt, sind wir seit Jahren Gastgeber.</p>
                <p>Mittlerweile ist es unserer Gemeinde gelungen, eine zwar noch ausbaufähige, aber durchaus
                    gut funktionierende jüdische Basis-Infrastruktur aufzubauen, zu der z.B. eine Synagoge,
                    rituelle Tauchbäder (Mikwaot), Rabbineramt, Tora-Unterricht für Erwachsene und Kinder,
                    Kinderbetreuung und vieles mehr gehört.</p>
                <p>Wir sind im regen Austausch mit Personen und Gruppen, die an einem Dialog mit uns als
                    Vertretern des religiösen Judentums interessiert sind oder mehr über die jüdische Religion
                    erfahren möchten.</p>


            </div><!-- /.blog-post -->

        </div><!-- /.blog-main -->

        <aside class="col-md-4 blog-sidebar">
            <app-info-box></app-info-box>
        </aside><!-- /.blog-sidebar -->

    </div><!-- /.row -->

</main><!-- /.container -->

<app-related-articles></app-related-articles>
