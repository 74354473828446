import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./../app.component.css']
})
export class HeaderComponent {
  collapsed = true;
}
